/* Modal */
html.modal-html {
    background: #fff;
    overflow: hidden;
}

.iframe-wrapper {
    overflow: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.iframe-wrapper iframe {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

.modal-container {
    background: #fff;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.modal-header {
    padding: .75rem 1.25rem;
}

.modal-header .close {
    font-size: 30px;
    opacity: 1;
}

h5.modal-title {
    font-size: 16px;
    line-height: 1.85em;
    padding: 0;
    margin: 0;
    width: 100%;
}

.modal-title a {
    text-decoration: none;
    float: right;
    font-style: normal;
}

.modal-title a i,
.modal-title a svg {
    margin-right: 5px;
}

.modal-content iframe {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
}

.modal-content .video-preview {
    text-align: center;
}

.modal-content .video-preview iframe {
    min-height: 0;
}

.popup-container h2,
.popup-container h3 {
    margin: 0 0 .75rem;
}

.popup-container p {
    margin: 0;
}

.popup-container .step-items h3,
.popup-container .step-items p {
    margin: 0.5rem 0 1rem;
}

.overlay-description {
    padding-bottom: 1rem;
    overflow: hidden;
}

#popup-div ul {
    max-width: 95%;
    padding-left: 20px;
}

.popup-container .inner-content {
    padding: 0 .5rem;
    overflow-x: hidden;
}

.modal-container .tools {
    overflow: hidden;
}

@media (min-width: 576px) {

    .modal-xs {
        max-width: 300px;
    }

    .modal-sm {
        max-width: 377px;
    }

    .modal-lg,
    .modal-xl {
        max-width: 550px;
    }
}


@media (min-width: 768px) {

    .modal-lg {
        max-width: 700px;
    }

    .modal-xl {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .modal-md {
        max-width: 610px;
    }

    .modal-lg {
        max-width: 800px;
    }

    .modal-xl {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    .modal-md {
        max-width: 610px;
    }

    .modal-lg {
        max-width: 900px;
    }

    .modal-xl {
        max-width: 1140px;
    }
}

@media print {
    /* Modal Printing */
    html {
        height: 100%;
    }

    body.modal-open .modal-header,
    body.modal-open .site-header,
    body.modal-open div#main,
    body.modal-open .site-footer,
    body.modal-open .tools,
    body.modal-open .modal-backdrop {
        display: none;
    }

    body.modal-open .modal-content iframe {
        overflow: hidden !important;
        width: 100%;
        height: 100%;
        border: 0 !important;
    }

    body.modal-open,
    body.modal-open .modal,
    body.modal-open .modal-dialog,
    body.modal-open .modal-container,
    body.modal-open .modal-content,
    body.modal-open .iframe-content,
    .popup-container .inner-content {
        padding: 0 !important;
        margin: 0 !important;
        position: static !important;
        overflow: visible !important;
        height: 100%;
        border: 0 !important;
    }

    body.modal-open .iframe-wrapper {
        height: 100%;
        width: 100%;
        overflow: hidden !important;
        padding: 0;
        margin: 0;
    }

    body.modal-open .modal-dialog {
        width: 100% !important;
        max-width: none !important;
        margin: 0 !important;
    }
}