$blue: rgba(35,105,161,1);
$lighterBlue: rgba(35,105,161,.8);
$gray: #F9F9F9;
$errorColor: #ff0000;

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

header {
    background: url("../images/css/header-bar.png") no-repeat bottom center;
    text-align: center;
    padding: .25rem 0;

    nav {
        display: flex;
        justify-content: center;
        width: 100%;

        @media (min-width: 768px) {
            justify-content: start;
        }
    }
}

.navbar-brand {
    img {
        max-width: 250px;
        margin: 0 auto;
        display: block;
    }
}

/* Anchors */

a {
    color: #000;
    transition: color 0.5s ease;
}

a:hover {
    color: $blue;
}

/* Forms */

.form-group {
    margin: 0 0 1rem;

    label {
        display: block;
        margin-bottom: .25rem;
    }
}

/* Modal */

body.modal-container,
.modal-body {
    background: $gray;

    p {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .tools {
        margin-top: 1rem;

        .btn {
            margin: 0 .5rem 0 0;
        }
    }
}


/* Validation Errors */

.validation-summary-errors,
.validation-summary {
    background: #E27021;
    color: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 400;

    a {
        color: #fff;
        text-decoration: underline;
    }

    ul {
        display: none;
    }
}

/* Buttons */

.btn {
    background: $blue;
    color: #fff;
    text-transform: uppercase;
    border-radius: .65rem;
    padding: .25rem 1.125rem;
    border: 1px solid transparent;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;

    &:hover {
        background: $lighterBlue;
        color: #fff;
    }

    &.secondary {
        background: transparent;
        color: $blue;
        border-color: $blue;

        &:hover {
            background: $lighterBlue;
            color: #fff;
        }
    }
}

/* Headers */

h1,
h2 {
    font-size: 18px;
    font-weight: bold;
}

h3 {
    font-size: 15px;
    font-weight: bold;
}

/* Look up */

.lookup-container {
    padding: 2rem 0;
    max-width: 976px;
    margin: 0 auto;

    h1 {
        margin-bottom: 1.5rem;
    }

    .code-container {
        .form-group {
            margin-bottom: 0;
        }
    }
}

.lookup-panel,
.help-panel {
    padding: 1rem;
}

.lookup-panel {
    margin-bottom: 1rem;
}

@media (min-width: 768px) {

    .lookup-panel {
        padding-right: 2rem;
    }

    .email-container,
    .order-id-container {
        max-width: 310px;
    }
}


.email-container {
    margin-bottom: 1rem;
}

.help-panel {

    h3 {
        font-size: 18px;
        font-weight: bold;
    }

    .callout {
        background: rgba(229, 234, 251, .7);
        padding: 2rem 1.5rem;
    }

    .help-question {
        font-weight: 600;
        margin: 1rem 0;
    }

    .help-answer {
        font-weight: 400;
    }
}
 
/* Code Verification */

.verify-notice {
    margin-bottom: .25rem;
}

.resend-code-container {
    &[data-resend-code-state="open"] {
        .code-sent {
            display: none;
        }
    }

    &[data-resend-code-state="closed"] {
        background: #FEDE00;
        padding: .25rem .5rem;

        .code-resend {
            display: none;
        }
    }
}

.code-container {
    margin-top: 1rem;
}

.resend-code {
    text-decoration: underline;
}

/* Manage Orders */
.manage-order-container {
    padding: 2rem;
    text-align: center;
    font-size: 14px;

    @media (min-width: 768px) {
        text-align: left;
        padding: 4rem 2rem;
    }

    @media (min-width: 992px) {
        padding: 5rem 2rem;
    }

    &.order-renewal {
        @media (min-width: 992px) {
            padding: 3rem 2rem;
        }
    }
}

.product-image,
.product-details,
.shipping-details,
.payment-renewal-information,
.payment-method-details {
    margin: 1.5rem auto;
    max-width: 75%;

    @media (min-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        max-width: none;
    }
}

.product-details,
.shipping-details,
.payment-info {
    a {
        text-decoration: underline;
    }
}

.product-image {
    text-align: center;

    img {
        max-width: 185px;

        @media (min-width: 992px) {
            max-width: 204px;
        }
    }
}

.product-details {
    margin-top: 1rem;

    h3 {
        margin-bottom: .15rem;
    }
}
.product-details,
.shipping-details {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5rem;

    @media (min-width: 768px) {
        border-bottom: 0;
        padding-bottom: 0;
    }

    @media (min-width: 992px) {
        border-bottom: 1px solid #ddd;
        padding-bottom: 1.5rem;
    }
}

.shipping-details {
    @media (min-width: 992px) {
        border-bottom: 0;
    }
}

.gift-certificate {
    .product-details, 
    .shipping-details {
        border-bottom: 0;
    }
}

.subscription-info {
    h2 {
        margin-bottom: 1rem;
    }

    @media (min-width: 768px) {
        border-right: 1px solid #ddd;
        padding: .5rem 1.5rem;
    }

    @media (min-width: 992px) {
        padding: .5rem 4rem .5rem 0;
    }

    @media (min-width: 1200px) {
        padding: .5rem 4rem .5rem 1rem;
    }
}

.payment-info {
    @media (min-width: 768px) {
        padding: .5rem 1.5rem;
    }

    @media (min-width: 992px) {
        padding: .5rem 0 .5rem 4rem;
    }
}

.manage-order-tool {
    margin: .25rem 0 .5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

/* Notice Bars */

.notice {
    width: 100%;
    text-align: center;
    padding: .65rem;

    p:last-child {
        padding: 0;
        margin: 0;
    }
}

.alert-bar {
    background: #FEF6DD;
    color: #FC4F2C;
    font-size: 16px;
}

.renewal-banner {
    background: #004767;
    color: #ffffff;
    font-size: 18px;
    margin: 2.5rem 0;
}

.payment-form {
    margin: 2.5rem 0;
}

.form-group.tools {
    margin-top: 1rem;
}

/* Billing and Payment Area */

.billing-entry {
    @media (min-width: 992px) {
        padding-right: 3rem;
        border-right: 1px solid #ddd;
    }
}

.payment-entry {
    @media (min-width: 992px) {
        padding-left: 3rem;
    }
}

.billing-payment-section {
    &.payment {
        @media (min-width: 768px) {
            max-width: 65%;
        }
    }
} 

/* Terms and Conditions */

.terms-conditions {
    margin: 2rem 0;
    font-size: 16px;
}

.popup-container {
    .terms-conditions {
        margin: 0;
    }
}

footer {
    padding: 1rem 0;
    font-size: 14px;
    border-top: 1px solid #ddd;
}

footer a {
    color: #000;
}

footer .row {
    padding: 0;
    margin: 0;
}

/* Credit Cards */

.cc-wrapper {
    position: relative;

    &:before {
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font: var(--fa-font-solid);
        content: "\f09d";
        position: absolute;
        top: .65rem;
        left: .5rem;
        font-size: 1.35rem;
    }

    &[data-card-type=visa]:before {
        font: var(--fa-font-brands);
        content: "\f1f0";
        font-size: 1.35rem;
    }

    &[data-card-type=amex]:before {
        font: var(--fa-font-brands);
        content: "\f1f3";
        font-size: 1.35rem;
    }

    &[data-card-type=mastercard]:before {
        font: var(--fa-font-brands);
        content: "\f1f1";
        font-size: 1.35rem;
    }

    &[data-card-type=discover]:before {
        font: var(--fa-font-brands);
        content: "\f1f2";
        font-size: 1.35rem;
    }

    input {
        text-align: right;
    }
}


.field-validation-error {
    color: $errorColor;
    margin-bottom: .5rem;

    span {
        span {
            text-transform: lowercase;
        }
    }
}